import React from "react"

import Layout from '../components/Layout'
import { Row, Col } from "react-bootstrap"
import { Helmet } from "react-helmet"
import * as data from '../data/work-data'
import { Link } from "gatsby"



const WorkPage = props => {
  const { pageContext } = props

  return (   

            <Layout page="work">
                <Helmet>
                    <body className="contact-body" />
                    <meta charSet="utf-8" />
                    <meta name="description" content="Work of The Able Guys" /> 
                    <title>Work | The Able Guys</title>
                </Helmet>

                <div className="consistent-gap">
                    <div className="text-center">
                            <h1 className="heading-careers mx-4">Work</h1>
                            <p className="card-text">Some of our best work</p>
                    </div>
                </div>
                

          <div className="container">

            <Row className="card-deck">
                {data.work.map((block, index) => {
                return(
                    <Col md={4} sm={6} xs={12} key={index} className="mt-5">
                        <a href={block.link} target="_blank"><img className="card-img-top rounded cursor-pointer" src={block.image} alt={block.title} /></a>
                        <div className="card-body ml-n3">
                            <h5 className="card-title">{block.title}</h5>
                            <p className="card-text">{block.description}</p>
                        </div>
                        <div className="card-footer bg-secondary">
                            <small className="text-white">→ {block.technologies}</small>
                        </div>
                    </Col>
                )})}
            </Row>
              
          </div>
        
        </Layout>

  )
}
export default WorkPage