export const work =  [
            {
                title: "Good Life",
                description: "Food, Grocery, Laundry, Pharmacy etc delivery app",
                technologies: "UI/UX, Backend, Android, iOS",
                image: "https://res.cloudinary.com/kraftycrux/image/upload/v1655302371/w_372,h_372,c_fill/g_auto,q_auto/work-gl",
                link: "http://google.peek.link/2Am2"
            },
            {
                title: "River Island",
                description: "River Island is a well-known fashion brand on the High Street",
                technologies: "UI/UX, Android, iOS",
                image: "https://res.cloudinary.com/kraftycrux/image/upload/w_372,h_372,c_fill/g_auto,q_auto/work-ri",
                link: "http://riverisland.peek.link/2Am3"
            },
            {
                title: "National Parks",
                description: "US National Parks app by National Geographic",
                technologies: "UI/UX, Backend, iOS",
                image: "https://res.cloudinary.com/kraftycrux/image/upload/w_372,h_372,c_fill/g_auto,q_auto/work-np",
                link: "http://apple.peek.link/2Am4"
            },
            {
                title: "Pottery Barn Teen",
                description: "A Pottery Barn brand catering to teenagers for clothes, beddings etc",
                technologies: "UI/UX, Web, Collaboration",
                image: "https://res.cloudinary.com/kraftycrux/image/upload/w_372,h_372,c_fill/g_auto,q_auto/work-pbt",
                link: "http://pbteen.peek.link/2Am5"
            },
            ,
            {
                title: "LunchBox",
                description: "Restaurants get their own ordering app and a website",
                technologies: "UI/UX, Marketing Website",
                image: "https://res.cloudinary.com/kraftycrux/image/upload/v1655318720/w_372,h_372,c_fill/g_auto,q_auto/work-lb",
                link: "http://lunchbox.peek.link/2Am6"
            },
            {
                title: "Virgin Money",
                description: "Smooth personal and business banking at your fingertips",
                technologies: "UI/UX, Web, Android, iOS, Unified API",
                image: "https://res.cloudinary.com/kraftycrux/image/upload/w_372,h_372,c_fill/g_auto,q_auto/work-vm",
                link: "http://virginmoney.peek.link/2Am7"
            },
            {
                title: "Nextdoor",
                description: "Neighborhood and community social network",
                technologies: "UI/UX, Web, Android, iOS, Backend",
                image: "https://res.cloudinary.com/kraftycrux/image/upload/w_372,h_372,c_fill/g_auto,q_auto/work-nd",
                link: "http://nextdoor.peek.link/2Am8"
            },
            {
                title: "Donut",
                description: "Savings on DeFi platform with 4% APY returns",
                technologies: "UI/UX, Web, Android, iOS, Backend",
                image: "https://res.cloudinary.com/kraftycrux/image/upload/w_372,h_372,c_fill/g_auto,q_auto/work-donut",
                link: "http://donut.peek.link/2Am9"
            },
            {
                title: "uLesson",
                description: "Easy Learning App for various courses from Africa",
                technologies: "UI/UX, Web, Android, iOS, Backend",
                image: "https://res.cloudinary.com/kraftycrux/image/upload/w_372,h_372,c_fill/g_auto,q_auto/work-ul",
                link: "http://ulesson.peek.link/2Ama"
            },
            {
                title: "Minta",
                description: "The Automated Content Marketing Platform that brands love",
                technologies: "UI/UX, Web",
                image: "https://res.cloudinary.com/kraftycrux/image/upload/w_372,h_372,c_fill/g_auto,q_auto/work-winta",
                link: "http://withminta.peek.link/2Amb"
            },
            {
                title: "RadiantBay",
                description: "Online Gold and Diamond jewellery selling brand",
                technologies: "UI/UX, Shopify",
                image: "https://res.cloudinary.com/kraftycrux/image/upload/w_372,h_372,c_fill/g_auto,q_auto/work-rb",
                link: "http://radiantbay.peek.link/2Ame"
            },
            {
                title: "WeHype",
                description: "Wehype is the go-to solution for gaming influencer marketing",
                technologies: "UI/UX, Web",
                image: "https://res.cloudinary.com/kraftycrux/image/upload/w_372,h_372,c_fill/g_auto,q_auto/work-wh",
                link: "http://wehype.peek.link/2Amd"
            },
            {
                title: "Fridge No More",
                description: "15 minute grocery delivery service in neighborhoods of US",
                technologies: "UI/UX, Web, Android, iOS, Backend",
                image: "https://res.cloudinary.com/kraftycrux/image/upload/w_372,h_372,c_fill/g_auto,q_auto/work-fnm",
                link: "http://fridgenomore.peek.link/2Amf"
            },
            {
                title: "Forks Over Knives",
                description: "Plant based eating. Recipes, Meal Planning and more",
                technologies: "UI/UX, Web, Android, iOS, Backend",
                image: "https://res.cloudinary.com/kraftycrux/image/upload/w_372,h_372,c_fill/g_auto,q_auto/work-fok",
                link: "http://forksoverknives.peek.link/2Amg"
            },
            {
                title: "Saraiva",
                description: "Brazil marketplace of books, games and films",
                technologies: "UI/UX, Backend revamp, Web, Android, iOS",
                image: "https://res.cloudinary.com/kraftycrux/image/upload/w_372,h_372,c_fill/g_auto,q_auto/work-saraiva",
                link: "http://com.peek.link/2Amh"
            },
]
